<template>
  <div class="container-fluid mt-2 mb-5">
    <vs-dialog not-center v-model="delSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>sensor</b></h4>
      </template>
      <div class="con-content">
        <p>Are you sure you want to delete the sensor?</p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog not-center v-model="addRecordModal">
      <template #header>
        <h4 class="not-margin">Add <b>manual record</b></h4>
      </template>
      <div class="con-content">
        <!-- <div class="card main-card"> -->
        <!-- <div class="card-body"> -->
        <div class="form-group">
          <p>Enter the value:</p>
          <input
            type="text"
            class="form-control form-control-sm mt-2"
            v-model.trim="manualNumber"
            placeholder="Enter a number"
          />
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="saveManualRecord()" transparent>
                Save record
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="addRecordModal = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <img src="@/assets/manual.png" width="33" class="me-2" />
        <h3 class="mb-0">Manual Records Data</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div
            class="col-md-4 mt-3"
            v-for="(item, index) in farms"
            :key="index"
          >
            <div class="card main-card h-100">
              <div class="card-body">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
                <!-- <span class="text-muted smallText"><span
                    class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br> -->

                <div
                  class="card card-station mt-1"
                  v-for="(itemStation, indexStation) in item.stations.filter(
                    (station) => station.sensor_type === 5
                  )"
                  :key="indexStation"
                >
                  <div class="card-body">
                    <!-- <span class="text-main-gradient-secondary">{{itemStation.name}}</span> <br> -->
                    <!-- <span class="material-icons smallText me-1">category</span>
                    <span class="text-muted smallText">{{item.stations[0].sensor_description}} </span> -->
                    <ul class="list-group smallText">
                      <li
                        class="list-group-item"
                        v-for="(item2, index2) in itemStation.sensors"
                        :key="index2"
                        v-if="parseInt(item2.sensor_type) == 5"
                      >
                        <template v-if="parseInt(item2.last_status) == 0">
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle-gray me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}
                          </span>
                          <!-- <a class="text-link" @click="
                              turnManual(
                                item2.id,
                                1,
                                item2.name,
                                item2.station_id,
                                itemStation.config,
                                item.storage_path,
                                item2.config
                              )
                            ">Turn on</a> -->
                          <a
                            class="text-link ms-2"
                            @click="addRecordDisplay(item2)"
                            >Add Record</a
                          >
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a
                            class="text-link ms-2"
                            @click="delete_manual(item2)"
                            >Delete</a
                          > -->
                        </template>
                        <template v-else>
                          <span
                            class="smallText d-flex align-items-center justify-content-left"
                          >
                            <div class="circle me-1"></div>
                            {{ item2.name }}, id: {{ item2.id }}
                          </span>
                          <a
                            class="text-link"
                            @click="
                              turnManual(
                                item2.id,
                                0,
                                item2.name,
                                item2.config,
                                itemStation.config,
                                item.storage_path
                              )
                            "
                            >Turn off</a
                          >
                          <a
                            class="text-link ms-2"
                            @click="addRecordDisplay(item2)"
                            >Add Record</a
                          >
                          <a class="text-link ms-2" @click="last_mesure(item2)"
                            >Last measure</a
                          >
                          <!-- <a class="text-link ms-2" @click="delete_manual(item2)">Delete</a> -->
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="col-md-8" v-if="lastMeasure && lastMeasure.length > 0">
              <h3 class="text-main-gradient-secondary">Last measure</h3>
              <p>
                <br />
                <strong
                  >{{ selectedSensor.id }} - {{ selectedSensor.name }} </strong
                ><br />
              </p>
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group smallText">
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >1.-Observation_time: </strong
                      >{{
                        moment(lastMeasure[0].observation_time)
                          .subtract(7, "days")
                          .format("MMM Do YYYY HH:m:ss")
                      }}
                    </li>
                    <li class="list-group-item">
                      <strong class="text-main-gradient-secondary"
                        >1.- Value: </strong
                      >{{ lastMeasure[0].manual_value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
import SetInterval from "set-interval";
import moment from "moment";
import wCodes from "../wCodes";

var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "ManualData",
  props: {
    itemData: Object, 
    userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
  },
  data() {
    return {
      user: null,
      farms: [],
      active: false,
      options: [],
      lastMeasure: [],
      selectedSensor: [],
      observations: [],
      delSensor: false,
      addRecordModal: false,
      sampleTime: 1200000,
      lightning: [],
      manualNumber: null,
    };
  },

  async mounted() {
      await this.getFarms();
      await this.getSensors();
      console.log("sensors", this.sensors);
  },
  methods: {
    extractUrl(configJson) {
      try {
        const config = JSON.parse(configJson);
        return config.config;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return ""; // o puedes devolver un mensaje de error si lo prefieres
      }
    },

    async getSensors() {
      await axios.get(`${API}/api-sensors`).then((response) => {
        this.sensors = response.data;
      });
    },

    storeRain(uid, stationId, rain) {
      let obj = { uid: uid, stationId: stationId, rain };
      localStorage.setItem(`r-${stationId}`, JSON.stringify(obj));
    },

    // this.getDataManual(stationId, station_config, id, name, path, sensor_config);
    async getDataManual(
      stationId,
      config,
      sensorId,
      name,
      path,
      sensor_config
    ) {
      var vm = this;
      console.log("Manual running.....", stationId, sensorId);
      let manualConfig = JSON.parse(config);

      console.log("CONFIG", manualConfig);
      await axios
        .get(`${API}/manual-last-measure/${sensorId}`)
        .then((data) => {
          console.log("MANUAL DATA");
          console.log(data.data);

          let response = data.data;

          let manualData = response;

          // ipcRenderer.send("createManualFile", {
          //   manualData,
          //   us,
          //   path,
          //   stationId,
          //   sensorId,
          //   name
          // });
        })

        .catch(function (error) {
          console.log("catch!!");
          console.log(error);
          // vm.displayError(`Error ${error}`);
          return;
        });
    },

    delete_manual(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      this.delSensor = true;
    },
    addRecordDisplay(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      this.addRecordModal = true;
      this.manualNumber = null;
    },

    saveManualRecord() {
      if (!this.vEmpty(this.manualNumber, "Value")) {
        return;
      }
      if (!this.vNumber(this.manualNumber, "Value")) {
        return;
      }

      axios
        .post(`${API}/saveManual`, {
          sensor_id: this.selectedSensor.id,
          manual_value: this.manualNumber,
        })
        .then((response) => {
          this.notify("success", "Record added successfully");
          this.last_mesure(this.selectedSensor);
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.addRecordModal = false;
        });
    },

    deleteSensor() {
      axios
        .post(`${API}/delete-manual-sensor`, {
          sensor_id: this.selectedSensor.id,
          station_id: this.selectedSensor.station_id,
        })
        .then((response) => {
          this.notify("success", "Sensor deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.delSensor = false;
          this.$emit("manual-deleted", true);
        });
    },

    async last_mesure(item) {
      this.selectedSensor = item;
      this.lastMeasure = [];
      try {
        const response = await axios.get(
          `${API}/manual-last-measure/${this.selectedSensor.id}`
        );
        setTimeout(() => {
          this.lastMeasure = response.data;
        }, 333);
        console.log(response);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },

    async turnManual(
      id,
      value,
      name,
      stationId,
      station_config,
      path,
      sensor_config
    ) {
      try {
        const response = await axios.post(`${API}/turnManual`, {
          id: id,
        });

        if (parseInt(value) == 0) {
          SetInterval.clear(`manual${id}`);
          this.notify("success", `Stopped ${name}`);
          this.$emit("manual-turned", false);
        } else {
          SetInterval.clear(`manual${id}`);
          console.log("BEFORE:::::::::");
          console.log(id, value, name, stationId, station_config, path);
          this.getDataManual(
            stationId,
            station_config,
            id,
            name,
            path,
            sensor_config
          );
          SetInterval.start(
            () => {
              this.getDataManual(
                stationId,
                station_config,
                id,
                name,
                path,
                sensor_config
              );
            },
            this.sampleTime,
            `manual${id}`
          );
          this.notify("success", `Started ${name}`);
          this.$emit("manual-turned", true);
        }
        this.getFarms();
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },

    async getFarms() {
      this.farms = [];
      try {
        const response = await axios.get(
          `${API}/getFarmsByOwnerSingle/${this.userId}/${this.itemData.id}`
        );
        this.farms = response.data;
        this.sampleTime = parseInt(this.farms[0].sampleTime * 60000);
        console.log("GET FARS: ", this.farms, "sampletime", this.sampleTime);
      } catch (error) {
        console.error("Error", error);
        this.notify("error", "Failed to fetch farms");
      }
    },
    async verifyDavis() {
      return;
      if (this.farms && this.farms.length > 0) {
        this.farms.forEach((farm) => {
          if (farm.stations && farm.stations.length > 0) {
            const soilStations = farm.stations.filter(
              (station) => station.sensor_type === 1
            );
            if (soilStations.length > 0) {
              console.log("Stations", soilStations);
              soilStations.forEach((station) => {
                if (station.sensors && station.sensors.length > 0) {
                  const activeSensors = station.sensors.filter(
                    (sensor) => sensor.last_status === 1
                  );
                  if (activeSensors.length > 0) {
                    activeSensors.forEach((element) => {
                      console.log(element);
                      SetInterval.start(
                        () => {
                          this.getDataManual(
                            element.config,
                            farm.storage_path,
                            element.id,
                            station.name
                          );
                        },
                        60000,
                        `soilScout${element.id}`
                      );
                      this.getDataManual(
                        element.config,
                        farm.storage_path,
                        element.id,
                        station.name
                      );
                    });
                  }
                }
              });
            }
          }
        });
      }
    },
  },
};
</script>
