import Vue from 'vue';
import { auth } from '../fb';
import { onAuthStateChanged } from "firebase/auth";
import axios from 'axios';
import store from '../store.js';
var config = require("@/config/api.json");
const API = config.api;

Vue.mixin({
    data() {
        return {
            notificationActive: false,
            loading: null,
            user: null,
            storedUser: null
        };
    },
    mounted() {
        try {
            this.storedUser = localStorage.getItem("user");
            if (this.storedUser) {
                this.storedUser = JSON.parse(this.storedUser);
            }
        } catch (e) {
            console.error("Error parsing stored user data:", e);
        }
    },
    methods: {
        scrollToBottom() {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        },
        hasPermission(dbName, permissions) {
            if (permissions) {
                return permissions.some(
                    (permission) => permission.dbName === dbName
                );
            }
            return false;
        },
        getUser() {
            return this.user;
        },
        async checkAuthState() {
            try {
                const user = await new Promise((resolve, reject) => {
                    onAuthStateChanged(auth, (user) => {
                        if (user) {
                            resolve(user);
                        } else {
                            reject('No user');
                        }
                    });
                });

                await this.handleAuthenticatedUser(user);
            } catch (error) {
                this.handleUnauthenticatedUser();
            }
        },
        async handleAuthenticatedUser(user) {
            const targetRoute = '/home';

            this.user = user;

            await this.$store.dispatch('updateDisplayName', this.user.displayName);

            let userWithPermissions;

            // Verificar si los datos del usuario están almacenados en localStorage
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                // Recuperar los datos del usuario y sus permisos del localStorage
                userWithPermissions = JSON.parse(storedUser);
            } else {
                // Obtener los permisos del usuario desde el servidor
                try {
                    const response = await axios.get(`${API}/manager-user/${this.user.uid}`);
                    userWithPermissions = response.data;
                    console.log(`${API}/manager-user/${this.user.uid}`)
                    console.log(`LEVEL:::::::::::::::::::: ${userWithPermissions.level}`)
                    // Almacenar los datos del usuario y sus permisos en localStorage
                    if (userWithPermissions.level != 'admin' && userWithPermissions.level != 'super' && userWithPermissions.level != 'technical') {
                        this.logout();
                        return;
                    }
                    localStorage.setItem('user', JSON.stringify(userWithPermissions));
                } catch (error) {
                    console.error('Error al obtener el usuario y sus permisos:', error);
                }
            }

            // Actualizar el estado con los datos del usuario y sus permisos
            if (userWithPermissions) {
                await this.$store.dispatch('updateUser', userWithPermissions);
            }

            // Redireccionar a la ruta de destino
            if (this.$route.path !== targetRoute) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.$router.push({ name: 'Home' });
            }
        },
        async updateUserInfo(uid) {
            // Obtener los permisos del usuario desde el servidor
            await axios.get(`${API}/manager-user/${uid}`)
                .then(response => {
                    const userWithPermissions = response.data;
                    // Actualizar el estado con el usuario y sus permisos
                    this.$store.dispatch('updateUser', userWithPermissions);

                    // Almacenar los datos del usuario y sus permisos en localStorage
                    localStorage.setItem('user', JSON.stringify(userWithPermissions));
                })
                .catch(error => {
                    console.error('Error al obtener el usuario y sus permisos:', error);
                });
        },
        handleUnauthenticatedUser() {
            this.user = null;

            const targetRoute = '/';
            if (this.$route.path !== targetRoute) {
                this.$router.push(targetRoute);
            }
        },
        logout() {
            localStorage.removeItem('user')
            auth.signOut()
                .catch((error) => {
                    this.notify('error', `Error al cerrar sesión: ${error.message}`);
                });
            const targetRoute = '/';
            if (this.$route.path !== targetRoute) {
                this.$router.push(targetRoute);
            }
        },
        notify(type, text) {
            if (this.notificationActive) {
                return;
            }

            let notificationConfig = {
                color: 'dark',
                title: '',
                text: text,
            };

            switch (type) {
                case 'success':
                    notificationConfig.title = '✅ Success message';
                    break;
                case 'error':
                    notificationConfig.title = '❌ Error message';
                    break;
                case 'info':
                    notificationConfig.title = 'ℹ️ Info message';
                    break;
            }

            this.$vs.notification(notificationConfig);

            this.notificationActive = true;

            setTimeout(() => {
                this.notificationActive = false;
            }, 3000);
        },
        openLoading() {
            this.loading = this.$vs.loading({
                background: '#214455',
                color: '#fff'
            })
        },
        closeLoading() {
            this.loading.close()
        },
        vEmpty(value, fieldName) {
            if (value == null || value.trim() === '') {
                this.notify('error', `Error: ${fieldName} field is empty`);
                return false;
            }
            return true;
        },

        vNumber(value, fieldName) {
            if (isNaN(value)) {
                this.notify('error', `Error: ${fieldName} field is not a valid number`);
                return false;
            }
            return true;
        },

        vEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }
    }
});