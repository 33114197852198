<template>
  <div class="container-fluid mt-2 mb-5">
    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h4 class="d-flex align-items-center">
          <span class="material-icons text-main-gradient me-2">menu</span>
          <strong>Welcome</strong>
        </h4>
        <p>AGTLink Dashboard.</p>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div class="col-md-4 mt-3">
            <div class="card main-card h-100">
              <div class="card-body">
                <TotalSensors />
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-3">
            <div class="card main-card h-100">
              <div class="card-body">
                <ActiveSensors />
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-3">
            <div class="card main-card h-100">
              <div class="card-body">
                <LatestUsers />
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-3">
            <div class="card main-card h-100">
              <div class="card-body">
                <LatestUserLogins />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { mapActions } from "vuex";
  import axios from "axios";
  import moment from "moment";
  import TotalSensors from "@/views/Total-sensors.vue";
  import LatestUsers from "@/views/LatestUsers.vue";
  import LatestUserLogins from "@/views/LatestUserLogins.vue";
  import ActiveSensors from "@/views/Active-Inactive-Sensors.vue";
  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    name: "Dashboard",
    components: {
      TotalSensors, ActiveSensors, LatestUsers,LatestUserLogins
    },
    data() {
      return {
        storedUser: [],
        user: null,
      };
    },

    mounted() {
      this.openLoading();
      setTimeout(() => {
        this.storedUser = localStorage.getItem("user");
        if (this.storedUser) {
          this.user = JSON.parse(this.storedUser);
        }
        this.closeLoading();
      }, 1000);
    },
    methods: {},
  };
</script>