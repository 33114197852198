<template>
  <div class="container-fluid mt-2 mb-5">
    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">Delete <b>user</b></h4>
      </template>
      <div class="con-content">
        <p>¿Are you sure you want to delete user {{ selectedUser.email }}?</p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteUser()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de usuario y permisos -->
    <vs-dialog prevent-close not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">Edit <b>user</b></h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="smallText">
            Contact Name:
            <input class="form-control" type="text" name="" id="" v-model="displayNameEdit" />
          </p>
          <p class="smallText">
            Company Name:
            <input class="form-control" type="text" name="" id="" v-model="companyNameEdit" />
          </p>

          <p class="smallText">
            Email:
            <input class="form-control" type="text" name="" id="" v-model="emailEdit" />
          </p>

          <p class="smallText text-end">
            Send password recovery email
            <a @click="sendRecovery()" class="text-link">here</a>
          </p>
          <p class="smallText" style="display: none">
            User level
            <select class="form-control mt-2" id="userLevel" v-model="selectedLevelEdit">
              <option value="manager">Manager</option>
              <option value="technical">Technical</option>
            </select>
          </p>
          <div class="form-group mt-3" v-if="showSwitch">
            <p class="smallText">App permissions</p>
            <div class="container">
              <div class="row">
                <div class="col-md-12 mt-2" v-for="permission in permissions" :key="permission.id">
                  <vs-switch v-model="selectedPermissionsEdit" :val="permission.id" :label="permission.name">
                    <template #off>
                      {{ permission.name }}
                    </template>
                    <template #on>
                      {{ permission.name }}
                    </template>
                  </vs-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="smallText">
          Click
          <a class="text-link smallText" @click="delUserModal(selectedUser)">here</a>
          to delete the user, please note that if you delete the user, all of
          their historical data will be removed from the database, this includes
          history of tiers, addons, stations and sensors.
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editUser()" transparent>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3>Client manager</h3>
          <p class="smallText">
            In this section you can create users (clients) of the system, assign
            tiers, addons, manage stations, create sub-users and check sensors
          </p>
        </div>
        <div class="col-md-12">
          <div class="card main-card">
            <div class="card-body">
              <h4>Create company owner</h4>
              <div class="form-group">
                <p class="smallText">
                  Contact Name
                  <input type="text" class="form-control mt-2" id="displayName" placeholder="John Doe"
                    v-model.trim="displayName" />
                </p>
                <p class="smallText">
                  Company Name
                  <input type="text" class="form-control mt-2" id="companyName" placeholder="E.g. Agtronix"
                    v-model.trim="companyName" />
                </p>
                <p class="smallText">
                  Email
                  <input type="text" class="form-control mt-2" id="displayName" placeholder="example@agtl.ink"
                    v-model.trim="email" />
                </p>
                <p class="smallText">
                  Password
                  <input type="password" class="form-control mt-2" placeholder="* * * * * *" aria-label="******"
                    v-model="password" />
                  <input type="password" class="form-control mt-2" placeholder="Confirm Password" aria-label="******"
                    v-model="confirmPassword" />
                </p>
                <p class="smallText" style="display: none">
                  User level

                  <select class="form-control mt-2" id="userLevel" v-model="selectedLevel">
                    <option value="manager">Manager</option>
                    <option value="technical">Technical</option>
                  </select>
                </p>
              </div>
              <div class="form-group mt-3" v-if="showSwitch">
                <p class="smallText">App permissions</p>
                {{ selectedPermissions }}
                <div class="row">
                  <div class="col-md-12 mt-2" v-for="permission in permissions" :key="permission.id">
                    <vs-switch v-model="selectedPermissions" :val="permission.id" :label="permission.name">
                      <template #off>
                        {{ permission.name }}
                      </template>
                      <template #on>
                        {{ permission.name }}
                      </template>
                    </vs-switch>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <!-- <button type="button" class="btn btn-primary mt-3" @click="createUser()"><span class="spanButton">Create
                    user</span></button> -->
                <vs-button @click="createUser()" block>Create owner</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card main-card mt-5 mb-5">
            <div class="card-body">
              <vs-table>
                <template #header>
                  <vs-input v-model="search" border placeholder="Search" />
                </template>
                <template #thead>
                  <vs-tr>
                    <vs-th sort @click="
                        users = $vs.sortData($event, users, 'displayName')
                      ">User</vs-th>
                    <vs-th sort @click="users = $vs.sortData($event, users, 'level')">Level</vs-th>
                    <vs-th sort @click="
                        users = $vs.sortData($event, users, 'displayName')
                      ">Operations</vs-th>
                  </vs-tr>
                </template>
                <template #tbody>
                  <vs-tr :key="i" v-for="(tr, i) in $vs.getSearch(users, search)" :data="tr">
                    <vs-td>
                      🏢<strong class="text-main-gradient-secondary">
                        {{ tr.company[0].company }} </strong><br />
                      {{ tr.displayName }} <br />
                      <small>{{ tr.email }}</small>
                    </vs-td>
                    <vs-td>owner</vs-td>
                    <vs-td>
                      <template v-if="tr.uid != user">
                        <a class="text-link smallText" @click="editUserModal(tr)"><span
                            class="material-icons smallText me-1">edit</span>Edit</a>
                        <br />
                        <a class="text-link smallText" @click="selectUser(tr)"><span
                            class="material-icons smallText me-1">check_circle</span>Select</a>
                        <br />
                      </template>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>

              <!-- <table class="table table-striped">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Level</th>
                    <th>Created</th>
                    <th>Operations</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in users" :key="index">
                    <td>

                      <strong class="text-main-gradient-secondary"> {{item.company[0].company}} </strong><br>
                      {{item.displayName}} <br>
                      <small>{{item.email}}</small>
                    </td>
                    <td>{{item.level}}</td>
                    <td>{{moment().format("MMM Do YYYY")}}</td>
                    <td>
                      <template v-if="item.uid != user">
                        <a class="text-link smallText" @click="editUserModal(item)"><span
                            class="material-icons smallText me-1">edit</span>Edit</a> <br>
                        <a class="text-link smallText" @click="delUserModal(item)" v-if="item.level!='admin'"><span
                            class="material-icons smallText me-1">delete</span>Delete</a>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>
        </div>

        <div class="col-md-3 mt-3" v-if="selectedUser && selectedUser.displayName && selectedUser.email">
          <div class="card main-card">
            <div class="card-body text-center">
              <!-- Poner imagen guardada en assest/user.webp  totalmente centrada-->
              <img src="@/assets/user.webp" alt="User" class="img-fluid rounded-circle" width="150" />
              <h5>
                <span class="material-icons">business</span>
                {{
                selectedUser.company && selectedUser.company.length > 0
                ? selectedUser.company[0].company
                : "--"
                }}
              </h5>
              <p>
                <span class="material-icons">person</span>
                {{ selectedUser.displayName }} <br />
                <span class="material-icons">email</span>
                {{ selectedUser.email }}
              </p>
              <hr />
              <ul class="nav nav-pills align-items-center align-items-sm-start w-100">

                <li class="w-100">
                  <a href="#" @click="displayChilds()" :class="{ selected: showChilds === true }"
                    @mouseover="handleMouseOver" @mouseleave="handleMouseLeave"
                    class="nav-link d-flex align-items-center">
                    <span class="material-icons text-main-gradient ">people</span>
                    <span class="d-sm-inline ms-2">Users</span></a>
                </li>
                <li class="nav-item w-100">
                  <a class="nav-link d-flex align-items-center" @click="displayTiers()"
                    :class="{ selected: showTiers === true }" href="#" @mouseover="handleMouseOver"
                    @mouseleave="handleMouseLeave">
                    <span class="material-icons text-main-gradient ">playlist_add</span> <span
                      class="d-sm-inline ms-2">Tiers</span></a>
                </li>
                <li class="nav-item w-100">
                  <a class="nav-link d-flex align-items-center" @click="displayAddons()"
                    :class="{ selected: showAddons === true }" href="#" @mouseover="handleMouseOver"
                    @mouseleave="handleMouseLeave">
                    <span class="material-icons text-main-gradient ">electrical_services</span> <span
                      class="d-sm-inline ms-2">Addons</span></a>
                </li>
                <li class="nav-item w-100">
                  <a class="nav-link d-flex align-items-center" @click="displayStations()"
                    :class="{ selected: showStations === true }" href="#" @mouseover="handleMouseOver"
                    @mouseleave="handleMouseLeave">
                    <span class="material-icons text-main-gradient ">agriculture</span> <span
                      class="d-sm-inline ms-2">Farms / stations</span></a>
                </li>
              </ul>
            </div>

          </div>
        </div>

        <div class="col-md-9 mt-3" v-if="selectedUser && selectedUser.displayName && selectedUser.email">
          <user-childs :userId="selectedUser.id" :uid="selectedUser.uid" v-if="showChilds"></user-childs>
          <user-tiers :userId="selectedUser.id" :uid="selectedUser.uid" v-if="showTiers"></user-tiers>
          <user-addons :userId="selectedUser.id" :uid="selectedUser.uid" v-if="showAddons"></user-addons>
          <user-stations :userId="selectedUser.id" :uid="selectedUser.uid" v-if="showStations"></user-stations>

        </div>


      </div>
    </div>
  </div>
  </div>
</template>

<script>
  var config = require("@/config/api.json");
  const API = config.api;
  import axios from "axios";
  import { auth } from "../fb";
  import UserChilds from '@/views/User-childs.vue';
  import UserTiers from '@/views/User-tiers.vue';
  import UserAddons from '@/views/User-addons.vue';
  import UserStations from '@/views/User-stations.vue';
  import { sendPasswordResetEmail } from "firebase/auth";

  export default {
    name: "Users",
    components: {
      UserChilds,
      UserTiers,
      UserAddons,
      UserStations,
    },
    data() {
      return {
        search: "",
        selectedLevel: "manager",
        selectedLevelEdit: "manager",
        permissions: [],
        selectedPermissions: [1, 2, 3, 4, 5, 6, 7],
        selectedPermissionsEdit: [1, 2, 3, 4, 5, 6, 7],
        displayName: null,
        companyName: null,
        email: "",
        password: "",
        confirmPassword: "",
        showSwitch: false,
        users: [],
        modalDelete: false,
        modalEdit: false,
        selectedUser: [],
        displayNameEdit: null,
        companyNameEdit: null,
        emailEdit: null,
        user: null,
        showChilds: false,
        showTiers: false,
        showAddons: false,
        showStations: false,
      };
    },

    watch: {
      // selectedLevel(newLevel) {
      //   if (newLevel === 'manager') {
      //     // Cambiar el estado o la propiedad para mostrar el vs-switch
      //     this.showSwitch = true;
      //   } else {
      //     // Cambiar el estado o la propiedad para ocultar el vs-switch
      //     this.showSwitch = false;
      //   }
      // },
      // selectedLevelEdit(newLevel) {
      //   if (newLevel === 'manager') {
      //     // Cambiar el estado o la propiedad para mostrar el vs-switch
      //     this.showSwitch = true;
      //   } else {
      //     // Cambiar el estado o la propiedad para ocultar el vs-switch
      //     this.showSwitch = false;
      //   }
      // }
    },

    mounted() {
      // Obtener los permisos desde el servidor
      this.getPermissions();
      this.getUsers();
    },
    methods: {

      displayTiers() {
        this.showTiers = !this.showTiers;
        this.showChilds = false;
        this.showAddons = false;
        this.showStations = false;
      },
      displayAddons() {
        this.showAddons = !this.showAddons;
        this.showChilds = false;
        this.showTiers = false;
        this.showStations = false;
      },
      displayStations() {
        this.showStations = !this.showStations;
        this.showChilds = false;
        this.showTiers = false;
        this.showAddons = false;
      },

      /*Create an async await method called: displayChilds to consume the endpoint of the API using axios with .get(${API}/manager-get-childs/), sending the selected user's id and uid as parameters. */
      async displayChilds() {
        this.showChilds = !this.showChilds;
        this.showTiers = false;
        this.showAddons = false;
        this.showStations = false;

      },
      handleMouseOver(event) {
        event.target.classList.add("hovered");
      },
      handleMouseLeave(event) {
        event.target.classList.remove("hovered");
      },
      selectUser(item) {
        this.selectedUser = [];
        this.showChilds = false;
        this.showTiers = false;
        this.showAddons = false;
        this.showStations = false;
        this.selectedUser = item;
        // Additional logic for selecting a user can be added here
        console.log("User selected:", item);
      },

      async editUser() {
        if (!this.vEmpty(this.emailEdit, "Email")) {
          return;
        }
        if (!this.vEmpty(this.displayNameEdit, "Contact Name")) {
          return;
        }

        if (!this.vEmpty(this.companyNameEdit, "Company Name")) {
          return;
        }

        if (
          this.displayNameEdit == this.selectedUser.displayName &&
          this.companyNameEdit == this.selectedUser.company[0].company &&
          this.emailEdit == this.selectedUser.email
        ) {
          this.modalEdit = false;
          return;
        }

        const updUser = {
          id: this.selectedUser.id,
          uid: this.selectedUser.uid,
          email: this.emailEdit,
          oldEmail: this.selectedUser.email,
          displayName: this.displayNameEdit,
          oldDisplayName: this.selectedUser.displayName,
          companyName: this.companyNameEdit,
          oldCompanyName: this.selectedUser.company[0].company,
        };

        this.openLoading();

        // Realizar la solicitud al servidor para crear el usuario
        await axios
          .post(`${API}/manager-update-user`, updUser)
          .then((response) => {
            this.selectedLevelEdit = "manager";
            this.selectedPermissionsEdit = [1, 2, 3, 4, 5, 6, 7];
          })
          .catch((error) => {
            // Manejar el error, mostrar una notificación de error, etc.
            console.log(error);
            this.notify("error", error.response.data.error);
            this.closeLoading();
            return;
          })
          .finally(() => {
            this.modalEdit = false;
            this.closeLoading();
            this.getUsers();
            this.notify("success", "The user was udpated successfully.");
          });
      },

      sendRecovery() {
        this.openLoading();

        sendPasswordResetEmail(auth, this.selectedUser.email)
          .then(() => {
            this.notify("success", "Password reset email sent");
          })
          .catch((error) => {
            this.notify("error", `Error: ${error.code}`);
          })
          .finally(() => {
            this.closeLoading();
          });
      },

      editUserModal(item) {
        console.log(item);
        this.modalEdit = true;
        this.selectedUser = [];
        this.selectedUser = item;
        this.displayNameEdit = this.selectedUser.displayName;
        this.companyNameEdit = this.selectedUser.company[0].company;
        this.emailEdit = this.selectedUser.email;
        this.selectedLevelEdit = this.selectedUser.level;
        this.selectedPermissionsEdit = this.selectedUser.permissions;
      },

      deleteUser() {
        // Envía la solicitud HTTP DELETE utilizando Axios
        axios
          .delete(
            `${API}/manager-delete-user/${this.selectedUser.id}/${this.selectedUser.uid}`
          )
          .then((response) => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            this.notify("success", "User deleted successfully");
          })
          .catch((error) => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          })
          .finally(() => {
            // Cierra el modal de eliminación
            this.getUsers();
            this.modalDelete = false;
            this.selectedUser = [];
          });
      },

      delUserModal(item) {
        this.modalEdit = false;
        this.modalDelete = true;
        this.selectedUser = [];
        this.selectedUser = item;
      },

      async getUsers() {
        this.users = [];
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const userObject = JSON.parse(storedUser);
          this.user = userObject.uid;

          await axios
            .get(`${API}/manager-get-users/`)
            .then((response) => {
              this.users = response.data;
            })
            .catch((error) => {
              console.error("Error fetching users:", error);
            });
        }
      },
      async getPermissions() {
        await axios
          .get(`${API}/permissions`)
          .then((response) => {
            this.permissions = response.data;
          })
          .catch((error) => {
            console.error("Error fetching permissions:", error);
          });
      },
      async createUser() {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          // Recuperar los datos del usuario y sus permisos del localStorage
          const userObject = JSON.parse(storedUser);

          // Validar los campos requeridos
          if (!this.vEmpty(this.displayName, "Contact Name")) {
            return;
          }
          // Validar los campos requeridos
          if (!this.vEmpty(this.companyName, "Company Name")) {
            return;
          }

          if (!this.vEmpty(this.email, "Email")) {
            return;
          }
          if (!this.vEmail(this.email)) {
            this.notify("error", "Error: Invalid email");
            return;
          }

          if (!this.vEmpty(this.password, "Password")) {
            return;
          }

          if (!this.vEmpty(this.confirmPassword, "Confirm Password")) {
            return;
          }

          if (this.password !== this.confirmPassword) {
            this.notify("error", "Passwords do not match");
            return;
          }

          if (
            this.selectedLevel == "manager" &&
            this.selectedPermissions.length == 0
          ) {
            this.notify("error", "Please select manager permissions");
            return;
          }

          // Crear el objeto de usuario a enviar al servidor
          const newUser = {
            partent_uid: userObject.parent_uid,
            displayName: this.displayName,
            company: this.companyName,
            email: this.email,
            password: this.password,
            level: this.selectedLevel,
            permissions: this.selectedPermissions,
          };

          this.openLoading();

          // Realizar la solicitud al servidor para crear el usuario
          await axios
            .post(`${API}/manager-create-users`, newUser)
            .then((response) => {
              this.displayName = "";
              this.email = "";
              this.password = "";
              this.selectedPermissions = [1, 2, 3, 4, 5, 6, 7];
              this.closeLoading();
              this.getUsers();
              this.notify("success", "The user was created successfully.");
            })
            .catch((error) => {
              // Manejar el error, mostrar una notificación de error, etc.
              console.log(error);
              this.notify("error", error.response.data.error);
              this.closeLoading();
              return;
            });
        }
      },
    },
  };
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
  }

  .nav-link:hover {
    transform: scale(1.09);
    transition: transform 0.2s ease;
    background-color: #46616e67 !important;
    /* background-color: #bcf0fe67 !important; */
    color: white !important;
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 9px #171717;
  }

  .selected {
    /* Estilos para el elemento seleccionado (cambialos según tus necesidades) */
    transform: scale(1.09);
    transition: transform 0.2s ease;
    background-color: #214455 !important;
    /* background-color: #90ffd2 !important; */
    color: white !important;
    /* border-radius: 0px 6px 6px 0px !important;
    box-shadow: 0px 1px 0px #4ae459; */
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 0px 2px 0px #235e47;
  }

  body.light-mode .nav-link:hover {
    transform: scale(1.09);
    transition: transform 0.2s ease;
    /* background-color: #46616e67 !important; */
    background-color: #bcf0fe67 !important;
    color: white !important;
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 6px #b2c7c2;
  }

  body.light-mode .selected {
    /* Estilos para el elemento seleccionado (cambialos según tus necesidades) */
    transform: scale(1.09);
    transition: transform 0.2s ease;
    /* background-color: #214455 !important; */
    background-color: #90ffd2 !important;
    color: white !important;
    /* border-radius: 0px 6px 6px 0px !important;
    box-shadow: 0px 1px 0px #4ae459; */
    border-radius: 6px 6px 6px 6px !important;
    box-shadow: 1px 1px 1px #4ae459;
  }
</style>