<template>
  <div>
    <h5 class="text-main-gradient-secondary"><span class="material-icons ">group</span> Latest users</h5>
    <p>Latest created users</p>
    <!-- Crea tabla bootstrap 5 para listar los usuarios obtenidos en la llaamada getLatestUsers -->
    <div class="table-responsive">
      <table class="table table-hover  table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in latestUsers" :key="user.id">
            <td>{{ user.email }}</td>
            <td>{{moment(user.creationTime).utc().format("ll")  }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
var config = require("@/config/api.json");
import moment from "moment";
const API = config.api;

export default {
  data() {
    return {
      latestUsers: []
    };
  },
  mounted() {
    this.getLatestUsers();
  },
  methods: {
    async getLatestUsers() {
        try {
          const response = await axios.get(`${API}/manager-get-latest-created-users`);
          this.latestUsers = response.data.users;
          console.log(response)
        } catch (error) {
          console.error("Error en getLatestUsers: ", error);
        }
    }
  }
};
</script>