<template>
  <div class="container">
    <vs-dialog blur prevent-close v-model="openSoil">
      <Soil :userId="userId" :uid="uid" :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openDavis">
      <Davis :userId="userId" :uid="uid" :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openCampbell">
      <Campbell :userId="userId" :uid="uid" :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openInternet">
      <Internet :userId="userId" :uid="uid" :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openManual">
      <Manual :userId="userId" :uid="uid" :itemData="selectedFarm" />
    </vs-dialog>

    <div class="row">
      <div class="col-md-4 mt-3" v-for="(item, index) in farms" :key="index"
        :class="{'expired': calculateTierDaysRemaining(item.endDate) <=0}">
        <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex }"
          @click="handleClickFarm(item, index)">
          <div class="card-header">
            <h5 class="text-main-gradient">{{ item.name }}</h5>
          </div>
          <div class="card-body">
            <p>
              {{ item.tName }} <br />
              <strong>Mode: </strong>{{ item.tierMode }} <br />
              <strong>Start Date: </strong>{{ moment.utc(item.startDate).format("ll") }} <br />
              <strong>End Date: </strong>{{ moment.utc(item.endDate).format("ll") }} <br />
              <template v-if="calculateTierDaysRemaining(item.endDate) >30">
                <span class="badge rounded-pill bg-success text-white smallText">Days Remaining:
                  {{calculateTierDaysRemaining(item.endDate)}}</span>
              </template>
              <template
                v-if="calculateTierDaysRemaining(item.endDate) <=30 && calculateTierDaysRemaining(item.endDate) >15">
                <span class="badge rounded-pill bg-warning text-white smallText float">Days Remaining:
                  {{calculateTierDaysRemaining(item.endDate)}}</span>
              </template>
              <template
                v-if="calculateTierDaysRemaining(item.endDate) <=15 && calculateTierDaysRemaining(item.endDate) >0">
                <span class="badge rounded-pill  text-white bg-danger smallText float">Days Remaining:
                  {{calculateTierDaysRemaining(item.endDate)}}</span>
                <span class="text-muted smallText">
                  <span class="material-icons smallText ms-3">refresh</span>
                </span>
              </template>

              <template v-if="calculateTierDaysRemaining(item.endDate) <=0">
                <span class="badge rounded-pill  text-white bg-danger smallText float">Expired</span>
                <span class="text-muted smallText renew-button">
                </span>
              </template>

            </p>
            <span class="text-muted smallText"><span class="material-icons smallText me-1">timer</span>
              Sample time {{ item.sampleTime }} minutes</span>
            <br />
            <span class="text-muted smallText"><span class="material-icons smallText me-1">cloud_download</span>
              {{ item.history }} backup data</span>
            <br />
            <span class="text-muted smallText"><span class="material-icons smallText me-1">folder</span>{{
              item.storage_path }}</span>
            <br />

            <span class="text-muted smallText" :class="{'cancelClick': calculateTierDaysRemaining(item.endDate) <=0}">
              <span class="material-icons smallText me-1">receipt</span>
              <a :href="item.receiptUrl" target="_blank" class="text-link smallText me-2">Receipt Url</a>
            </span>
            <span class="text-muted smallText " :class="{'cancelClick': calculateTierDaysRemaining(item.endDate) <=0}">
              <span class="material-icons smallText me-1">history</span>
              <a href="#" @click="displayBackup(item)" class="text-link smallText me-2">Donwload Backup</a>
            </span>
            <!-- <span class="text-muted smallText"
              :class="{'cancelClick': calculateTierDaysRemaining(item.endDate) <=0}"><span
                class="material-icons smallText me-1">folder</span>
              <a href="#" @click="handleFileUpload(item)" class="text-link smallText me-2">Change strage path</a>
            </span> -->
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="row mt-5" v-if="selectedFarm">
          <div class="col-md-12">
            <h3 class="text-main-gradient-secondary">
              Available features for: {{ selectedFarm.name }}
            </h3>
            <p>Select a feature to start or see configurations.</p>
          </div>
          <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'scouts'">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex2 }">
              <div class="card-body text-center">
                <img src="@/assets/scouts.png" width="33" />
                <h5>Soil Scouts</h5>
                <p>Available items: {{ value }}</p>
                <!-- <p>ID de la estación: {{ selectedFarm.stations.find(station => station.sensor_type === 3).id }}</p> -->

                <template v-if="parseInt(value) == 10">
                  <a class="text-link smallText ms-2" @click="openSoil = true">Add sensor</a>
                </template>
                <template v-else>
                  <template v-if="parseInt(value) > 0">
                    <a class="text-link smallText" @click="openSoil = true">Add
                      sensor</a>
                    <a class="ms-3 text-link smallText" @click="displaySoilData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index === 'davis'">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex2 }">
              <div class="card-body text-center">
                <img src="@/assets/davis.png" width="33" />
                <h5>Davis W.S</h5>
                <p>Available items: {{ value }}</p>
                <!-- <p>ID de la estación: {{ selectedFarm.stations.find(station => station.sensor_type === 1).id }}</p> -->
                <template v-if="parseInt(value) == 2">
                  <a class="text-link smallText ms-2" @click="openDavis = true">Add sensor</a>
                </template>
                <template v-else>
                  <template v-if="parseInt(selectedFarm.realTierId) ==1">
                    <template v-if="parseInt(value) == 1">
                      <a class="text-link smallText ms-2" @click="openDavis = true">Add sensor</a>
                    </template>
                    <template v-else>
                      <template v-if="parseInt(value) === 0">
                        <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                        <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                          Records</a>
                      </template>
                      <template v-else>
                        <a class="text-link smallText" @click="openDavis=true">Add sensor</a>
                        <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                          Records</a>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(value) > 0">
                      <a class="text-link smallText" @click="openDavis = true">Add
                        sensor</a>
                      <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                    <template v-if="parseInt(value) === 0">
                      <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                      <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'campbell'">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex2 }">
              <div class="card-body text-center">
                <img src="@/assets/campbell.png" width="33" />
                <h5>Campbell W.S</h5>
                <p>Available items: {{ value }}</p>
                <template v-if="parseInt(value) == 1">
                  <a class="text-link smallText ms-2" @click="openCampbell = true">Add sensor</a>
                </template>
                <template v-else>
                  <template v-if="parseInt(value) > 1">
                    <a class="text-link smallText" @click="openCampbell = true">Add
                      sensor</a>
                    <a class="ms-3 text-link smallText" @click="displayCampbellData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                  <template v-if="parseInt(value) === 0">
                    <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                    <a class="ms-3 text-link smallText" @click="displayCampbellData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'internet'">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex2 }">
              <div class="card-body text-center">
                <img src="@/assets/internet.png" width="33" />
                <h5>Internet Weather</h5>
                <p>Available items: {{ value }}</p>
                <template v-if="parseInt(value) == 2">
                  <a class="text-link smallText ms-2" @click="openInternet = true">Add sensor</a>
                </template>
                <template v-else>
                  <template v-if="parseInt(value) <= 2 && parseInt(value) >= 1">
                    <a class="text-link smallText" @click="openInternet = true">Add
                      sensor</a>
                    <a class="ms-3 text-link smallText" @click="displayInternetData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                  <template v-if="parseInt(value) === 0">
                    <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                    <a class="ms-3 text-link smallText" @click="displayInternetData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'manual'">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex2 }">
              <div class="card-body text-center">
                <img src="@/assets/manual.png" width="33" />
                <h5>Manual Records</h5>
                <p>Available items: ♾️</p>
                <template v-if="parseInt(value) == 999999">
                  <a class="text-link smallText ms-2" @click="openManual = true">Add sensor</a>
                </template>
                <template v-else>
                  <template v-if="parseInt(value) <= 999998">
                    <a class="text-link smallText" @click="openManual = true">Add
                      sensor</a>
                    <a class="ms-3 text-link smallText" @click="displayManualData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                  <template v-if="parseInt(value) === 0">
                    <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                    <a class="ms-3 text-link smallText" @click="displayManualData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- Sensors data -->
    <div class="row mt-3" v-if="showSoilData">
      <SoilData :userId="userId" :uid="uid" :itemData="selectedFarm" @soil-turned="handleSoilTurned"
        @soil-deleted="handleSoilDeleted" />
    </div>
    <div class="row mt-3" v-if="showDavisData">
      <DavisData :userId="userId" :uid="uid" :itemData="selectedFarm" @davis-turned="handleDavisTurned"
        @davis-deleted="handleDavisDeleted" />
    </div>
    <div class="row mt-3" v-if="showCampbellData">
      <CampbellData :userId="userId" :uid="uid" :itemData="selectedFarm" @campbell-turned="handleCampbellTurned"
        @campbell-deleted="handleCampbellDeleted" />
    </div>
    <div class="row mt-3" v-if="showInternetData">
      <InternetData :userId="userId" :uid="uid" :itemData="selectedFarm" @internet-turned="handleInternetTurned"
        @internet-deleted="handleInternetDeleted" />
    </div>
    <div class="row mt-3" v-if="showManualData">
      <ManualData :itemData="selectedFarm" :userId="userId" :uid="uid" @manual-turned="handleManualTurned"
        @manual-deleted="handleManualDeleted" />
    </div>
    <!-- Sensors data -->
  </div>
</template>

<script>
  import axios from "axios";
  var config = require("@/config/api.json");
  const API = config.api;
  import moment from "moment";
  import Soil from "@/views/Soil.vue";
  import SoilData from "@/views/Soil-Scouts.vue";
  import Davis from "@/views/Davis.vue";
  import DavisData from "@/views/Davis-data.vue";
  import Campbell from "@/views/Campbell.vue";
  import CampbellData from "@/views/Campbell-data.vue";
  import Internet from "@/views/Internet.vue";
  import InternetData from "@/views/Internet-data.vue";
  import Manual from "@/views/Manual.vue";
  import ManualData from "@/views/Manual-data.vue";
  export default {
    props: {
      userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
    },
    components: {
      Soil,
      SoilData,
      Davis,
      DavisData,
      Campbell,
      CampbellData,
      Internet,
      InternetData,
      Manual,
      ManualData,
    },
    watch: {
      openSoil(value) {
        console.log(this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEvent(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openDavis(value) {
        console.log("OPEN DAVIS", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventDavis(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openCampbell(value) {
        console.log("OPEN CAMPBELL", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventCampbell(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openInternet(value) {
        console.log("OPEN INTERNET", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventInternet(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openManual(value) {
        console.log("OPEN INTERNET", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventManual(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
    },
    data() {
      return {
        showSoilData: false,
        showDavisData: false,
        showCampbellData: false,
        showInternetData: false,
        showManualData: false,
        selectedFarm: null,
        farms: [],
        selectedCardIndex: -1,
        selectedCardIndex2: -1,
        openSoil: false,
        openDavis: false,
        openCampbell: false,
        openInternet: false,
        openManual: false,
      };
    },
    mounted() {
      console.log(this.userId);
      console.log(this.uid);
      this.getUserFarms();
    },
    methods: {
      displayManualData(item, index) {
      this.selectedCardIndex2 = index;
      this.showDavisData = false;
      this.showCampbellData = false;
      this.showInternetData = false;
      this.showManualData = false;
      this.showSoilData = false;
      setTimeout(() => {
        this.showManualData = true;
      }, 333);
      console.log(item);
    },
      handleClickFarmCloseEventManual(item, index) {
        this.getUserFarms();
        this.selectedFarm = null;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showManualData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
        }, 333);
      },
      handleManualDeleted() {
        console.log(
          "llega manual deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventManual(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleManualTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      displayInternetData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        setTimeout(() => {
          this.showInternetData = true;
        }, 333);
        console.log(item);
      },
      handleInternetDeleted() {
        console.log(
          "llega campbell deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventInternet(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleInternetTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleClickFarmCloseEventInternet(item, index) {
        this.getUserFarms();
        this.selectedFarm = null;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        setTimeout(() => {
          this.showInternetData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
        }, 333);
      },
      displayCampbellData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        setTimeout(() => {
          this.showCampbellData = true;
        }, 333);
        console.log(item);
      },
      handleClickFarmCloseEventCampbell(item, index) {
        this.getUserFarms();
        this.selectedFarm = null;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showCampbellData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleCampbellDeleted() {
        console.log(
          "llega campbell deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventCampbell(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleCampbellTurned() {
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      displayDavisData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        setTimeout(() => {
          this.showDavisData = true;
        }, 333);
        console.log(item);
      },
      handleClickFarmCloseEventDavis(item, index) {
        this.getUserFarms();
        this.selectedFarm = null;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showDavisData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleDavisDeleted() {
        console.log(
          "llega davis deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventDavis(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleDavisTurned() {
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleSoilTurned() {
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleSoilDeleted() {
        console.log(
          "llega soil deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEvent(this.selectedFarm, this.selectedCardIndex);
      },
      displaySoilData(item, index) {
        console.log("soil data display", item);
        this.scrollToBottom();
        this.selectedCardIndex2 = index;
        this.showSoilData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showDavisData = false;
        setTimeout(() => {
          this.showSoilData = true;
        }, 333);
        console.log(item);
      },
      handleClickFarmCloseEvent(item, index) {
        console.log(item, index);
        this.getUserFarms();
        this.selectedFarm = null;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;

        setTimeout(() => {
          this.showSoilData = true;
          this.showDavisData = false;
          this.showCampbellData = false;
          this.showInternetData = false;
          this.showManualData = false;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
        }, 333);
      },
      handleClickFarm(item, index) {
        console.log('entr')
        this.selectedFarm = null;
        this.showSoilData = false;
        setTimeout(() => {
          this.selectedCardIndex = index;
          this.selectedFarm = item;
          this.scrollToBottom()
        }, 33);
        setTimeout(() => {
          this.scrollToBottom()
        }, 39);

      },
      calculateTierDaysRemaining(theEndDate) {
        const today = new Date();
        const endDate = new Date(theEndDate);
        const remainingDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
        return remainingDays;
      },
      calculateAddonsDaysRemaining(endDate) {
        const today = moment();
        const end = moment(endDate);
        return end.diff(today, 'days');
      },
      async getUserFarms() {
        this.farms = [];
        console.log(`${API}/getFarmsByOwner/${this.userId}`);
        try {
          const response = await axios.get(
            `${API}/getFarmsByOwner/${this.userId}`
          );
          this.farms = response.data;
          console.log("FARMS");
          console.log(response.data);
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch fawwewewewrms");
        }
      },
    },
  };
</script>