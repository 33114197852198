<template>
  <div class="container-fluid mt-2 mb-5">
    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete farm
          <strong>{{ selectedFarm.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteFarm()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Modal eliminar sensor soil-->
    <vs-dialog not-center v-model="modalDeleteSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>farm</b></h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete
          <strong>{{ selectedSensor.name }}</strong> ?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDeleteSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">Edit <b>farm</b></h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="text-muted">Name</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Blue berry domain"
            v-model.trim="nameEdit"
            maxlength="50"
          />

          <p class="text-muted mt-3">Storage Path</p>
          <input
            type="text"
            class="form-control mr-1"
            placeholder="Select a Path"
            v-model.trim="storage_pathEdit"
            maxlength="500"
            disabled
          />
          <a
            href="#"
            class="text-link smallText me-2"
            v-on:click="handleFileUpload()"
            >Select Path</a
          >
          <p class="text-muted">
            Storage path is the place where all the text files that feed the ICC
            software will be stored.
          </p>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editFarm()" block> Save Changes </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de granja -->
    <vs-dialog not-center v-model="modalConnect">
      <template #header>
        <h4 class="not-margin">{{ selectedFarm.name }} domain</h4>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <p class="smallText">
                Sensor type acording with your station
                <select class="form-control mt-2" id="sensor_type" v-model="sensor_type" disabled>
                  <option :value="item.id" v-for="(item, index) in sensor_types" :key="index">
                    {{ item.sensor_type }}
                  </option>
                </select>
              </p>
            </div>
            <hr />
            <!-- DAVIS CONFIG -->
            <div class="col-md-12 mt-3">

              <p class="text-muted mt-3">Enter api-key</p>
              <input type="text" class="form-control mr-1" placeholder="api-secret" v-model.trim="apiKey" maxlength="50"
                @focus="clearButton" />

              <p class="text-muted mt-3">Enter api-secret</p>
              <input type="text" class="form-control mr-1" placeholder="api-secret" v-model.trim="apiSecret"
                maxlength="50" @focus="clearButton" />

              <vs-button class="mt-3" @click="testDavisConnection()" block>
                Test API
              </vs-button>
              <hr />
              <template v-if="davisInstruments.length > 0">
                <p class="text-muted mt-3">Select a station</p>
                <select class="form-control mt-2 mb-3" id="davisInstruments" v-model="selectedDavisInstrument">
                  <option value="0">Select a station</option>
                  <option :value="item" v-for="(item, index) in davisInstruments" :key="index">
                    {{ item.station_id }} - {{ item.station_name }}
                  </option>
                </select>

                <template v-if="selectedDavisInstrument!=0">
                  <p v-if="selectedDavisInstrument"><strong>{{selectedDavisInstrument.station_name}}</strong>
                  <ul class="list-group smallText">
                    <li class="list-group-item"><strong>station_id:</strong> {{ selectedDavisInstrument.station_id }}
                    </li>
                    <li class="list-group-item"><strong>username:</strong> {{ selectedDavisInstrument.username }}</li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.active"><strong>is_active: </strong>
                      <span class="badge rounded-pill bg-success text-white"
                        v-if="selectedDavisInstrument.active">Active</span>
                      <span class="badge rounded-pill bg-secondary" v-else>Inactive</span>
                    </li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.firmware_version">
                      <strong>firmware_version:</strong> {{ selectedDavisInstrument.firmware_version }}</li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.meid"><strong>meid:</strong> {{
                      selectedDavisInstrument.meid }}</li>
                    <li class="list-group-item"><strong>registered_date:</strong> {{
                      moment.unix(selectedDavisInstrument.registered_date).format('ll')}}</li>
                    <li class="list-group-item"><strong>subscription_end_date:</strong> {{
                      moment.unix(selectedDavisInstrument.subscription_end_date).format('ll')}}</li>
                    <li class="list-group-item"><strong>location:</strong> {{ selectedDavisInstrument.city }} , {{
                      selectedDavisInstrument.region }} , {{ selectedDavisInstrument.country }}</li>
                  </ul>
                  </p>
                </template>
              </template>


              <div class="form-group d-none">
                <p>Sensor name:
                  <input type="text" class="form-control form-control-sm"
                    v-model.trim="selectedDavisInstrument.station_name"
                    placeholder="Enter a name to recognize the API more quickly" />
                </p>
              </div>
              <div class="form-group d-none">
                <p>Sensor ID value:
                  <input type="text" class="form-control form-control-sm"
                    v-model.trim="selectedDavisInstrument.station_id" placeholder="User a numeric value" />
                </p>
              </div>
            </div>
            <!-- DAVIS CONFIG -->
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="saveDavisSensor()" block>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalConnect = false" danger block>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>


   
    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h3>Davis Config</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5" v-show="soilConfig">
        <div class="form-group">
          <!-- <p class="text-muted">Name</p> -->
          <input type="hidden" class="form-control mr-1" placeholder="Station name or identifier"
            v-model.trim="stationName" maxlength="50" />

          <p class="text-muted mt-3">
            Select a sensor type
            <select class="form-control mt-2" id="station_sensor" v-model="station_sensor" disabled>
              <option value="0">Select a sensor type</option>
              <option :value="item.id" v-for="(item, index) in sensor_types" :key="index">
                {{ item.sensor_type }}
              </option>
            </select>
          </p>

          <!-- SOIL SCOUTS CONFIG -->
          <template v-if="showSoilForm">
            <p>Davis Config</p>
            <p class="text-muted">Enter your Davis API credentials</p>

            <!-- <div class="col-md-4">
                      <vs-switch color="#214455" v-model="selectedAgt" label="Agtronix Soil Scouts API">
                        <template #off> Use my own credentials </template>
                        <template #on> Default Agtronix </template>
                      </vs-switch>
                    </div> -->

            <template v-if="!selectedAgt">
              <p class="text-muted mt-3">Enter api-key</p>
              <input type="text" class="form-control mr-1" placeholder="api-secret" v-model.trim="apiKey" maxlength="50"
                @focus="clearButton" />

              <p class="text-muted mt-3">Enter api-secret</p>
              <input type="text" class="form-control mr-1" placeholder="api-secret" v-model.trim="apiSecret"
                maxlength="50" @focus="clearButton" />

              <vs-button class="mt-3" @click="testDavisConnection()" block>
                Test API
              </vs-button>
              <hr />
              <template v-if="davisInstruments.length > 0">
                <p class="text-muted mt-3">Select a station</p>
                <select class="form-control mt-2 mb-3" id="davisInstruments" v-model="selectedDavisInstrument">
                  <option value="0">Select a station</option>
                  <option :value="item" v-for="(item, index) in davisInstruments" :key="index">
                    {{ item.station_id }} - {{ item.station_name }}
                  </option>
                </select>

                <template v-if="selectedDavisInstrument!=0">
                  <p v-if="selectedDavisInstrument"><strong>{{selectedDavisInstrument.station_name}}</strong>
                  <ul class="list-group smallText">
                    <li class="list-group-item"><strong>station_id:</strong> {{ selectedDavisInstrument.station_id }}
                    </li>
                    <li class="list-group-item"><strong>username:</strong> {{ selectedDavisInstrument.username }}</li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.active"><strong>is_active: </strong>
                      <span class="badge rounded-pill bg-success text-white"
                        v-if="selectedDavisInstrument.active">Active</span>
                      <span class="badge rounded-pill bg-secondary" v-else>Inactive</span>
                    </li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.firmware_version">
                      <strong>firmware_version:</strong> {{ selectedDavisInstrument.firmware_version }}
                    </li>
                    <li class="list-group-item" v-if="selectedDavisInstrument.meid"><strong>meid:</strong> {{
                      selectedDavisInstrument.meid }}</li>
                    <li class="list-group-item"><strong>registered_date:</strong> {{
                      moment.unix(selectedDavisInstrument.registered_date).format('ll')}}</li>
                    <li class="list-group-item"><strong>subscription_end_date:</strong> {{
                      moment.unix(selectedDavisInstrument.subscription_end_date).format('ll')}}</li>
                    <li class="list-group-item"><strong>location:</strong> {{ selectedDavisInstrument.city }} , {{
                      selectedDavisInstrument.region }} , {{ selectedDavisInstrument.country }}</li>
                  </ul>
                  </p>
                </template>
              </template>
            </template>

          </template>

          <p class="text-muted mt-3">Farm / Domain</p>
          <select class="form-control" v-model="stationFarmId" disabled>
            <option v-for="(item, index) in allFarms" :key="index" :value="item.id">
              {{ item.name }}
            </option>
          </select>

          <!-- <button type="submit" class="btn btn-primary  mt-2" @click="createStation()"><span
                  class="spanButton">Save Station</span></button> -->
          <vs-button class="mt-3" @click="createStation()" v-if="showSaveDavis && selectedDavisInstrument!=0" block>
            Save Configuration
          </vs-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" v-for="(item, index) in farms" :key="index">
        <template v-if="item.id == itemData.id && item.sensor_type == 1">
          <h5 class="text-main-gradient-secondary">{{ item.name }}</h5>
          <p>
            <!-- <span class="text-main-gradient-secondary">{{item.stationName}} </span> <br> -->
            <span class="material-icons smallText me-1">category</span>
            <span class="text-muted smallText">{{ item.sensorName }} </span>
            <br />
            <span class="text-muted smallText"><span class="material-icons smallText me-1">folder</span>{{
              item.storage_path }}</span>
            <br />
            <small class="text-muted smallText"><span class="material-icons smallText me-1">calendar_month</span>Created
              at:
              {{
              moment(item.cdt).format("ll") }}</small>
          </p>
          <p>
            <a class="text-link smallText me-2" @click="connectSensor(item)"><span
                class="material-icons smallText">cable</span>Connect
              Station</a>
            <!-- <a class="text-link smallText me-2" @click="editUserModal(item)"><span
                      class="material-icons smallText">edit</span>Edit</a> -->
            <!-- <a class="text-link smallText" @click="delFarmModal(item)"><span
                      class="material-icons smallText ">delete</span>Delete Farm</a> -->
            <a class="text-link smallText" @click="seeSensors(item)"><span
                class="material-icons smallText">keyboard_arrow_down</span>See Stations</a>
          </p>
          <ul class="list-group smallText">
            <li class="list-group-item" v-for="(item, index) in soilSensors" :key="index">
              <strong>-{{ item.sensorId }}: {{ item.name }}</strong>
              <br />
              <!-- <a class="text-link smallText" @click="delSensorModal(item)"><span
                        class="material-icons smallText ">delete</span>Delete Sensor</a> -->
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
var config = require("@/config/api.json");
const API = config.api;
export default {
  name: "Davis",
  props: {
    itemData: Object,
    userId: {
        type: Number,
        required: true,
      },
      uid: {
        type: String,
        required: true,
      },
  },
  data() {
    return {
      name: null,
      storage_path: null,
      user: null,
      farms: [],
      allFarms: [],
      sensor_types: [],
      modalDelete: false,
      modalEdit: false,
      selectedFarm: [],
      modalConnect: false,
      nameEdit: null,
      storage_pathEdit: null,
      sensor_type: 1,
      showSoilConfig: false,
      station_sensor: 1,
      showSoilForm: true,
      stationName: "agt",
      stationFarmId: null,
      lastId: null,
      apiKey: null,
      apiSecret: null,
      selectedAgt: false,
      davisName: null,
      davisValue: null,
      soilSensors: [],
      selectedSensor: [],
      modalDeleteSensor: false,
      sensorParent: [],
      soilConfig: true,
      showSaveDavis: false,
      davisInstruments: [],
        davisInstrumentsValue: 0,
        selectedDavisInstrument: 0,
    };
  },
  watch: {
    station_sensor(value) {
      this.showSoilForm = false;
      switch (value) {
        case 3: // Soil Scouts
          this.showSoilForm = true;
          break;

        default:
          break;
      }
    },
    sensor_type(value) {
      this.showSoilConfig = false;
      switch (value) {
        case 1: // Davis
          this.showSoilConfig = true;
          break;

        default:
          break;
      }
    },
  },
  mounted() {
      this.getFarms();
      this.getAllFarms();
      this.getSensorType();


    setTimeout(() => {
      console.log("Datos del elemento seleccionado:", this.itemData);
      const sensorWithType1 = this.itemData.stations.find(
        (station) => station.sensor_type === 1
      );

      console.log(sensorWithType1);

      if (sensorWithType1 && sensorWithType1.id) {
        console.log(sensorWithType1.id);
        this.lastId = sensorWithType1.id;
      }
      this.getStation();
      // this.stationFarmId = this.allFarms[0].id;
      this.stationFarmId = this.itemData.id;
    }, 500);

    // ipcRenderer.on("getRoute", (event, args) => {
    //   if (!args.route[0]) {
    //     this.notify(
    //       "error",
    //       "There is no path selected, plese select it again"
    //     );
    //     return;
    //   } else {
    //     var regex = /\\/g;
    //     var FilePath = args.route[0].replace(regex, "\\\\");
    //     this.storage_path = FilePath;
    //     this.storage_pathEdit = FilePath;
    //   }
    // });
  },
  methods: {
    clearButton() {
      this.showSaveDavis = false;
    },
    async getStation() {
      console.log("station", this.itemData);
      await axios
        .get(`${API}/getStationbyId/${this.itemData.id}/1`)
        .then((data) => {
          let response = data.data;
          console.log("STATION", response);
          if (response.length > 0) {
            this.soilConfig = false;
          }
        })
        .catch(function (error) {
          console.log("entra");
          console.log(error);
        });
    },

    handleFileUpload() {
      // ipcRenderer.send("select-dirs", true);
    },

    delSensor() {
      axios
        .delete(`${API}/deleteSensor/${this.selectedSensor.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.seeSensors(this.sensorParent);
          this.modalDeleteSensor = false;
        });
    },

    delSensorModal(item) {
      this.modalDeleteSensor = true;
      this.selectedSensor = item;
    },

  
    async seeSensors(item) {
        var vm = this;

        console.log("see sensors", item);
        this.sensorParent = [];
        this.sensorParent = item;
        this.soilSensors = [];

        await axios
          .get(`${API}/seeSensors/${item.stationId}/1`)
          .then(function (data) {
            let response = data.data;
            if (response.length > 0) {
              vm.soilSensors = response;
              response.forEach((element, index) => {
                console.log(element.config);
                let config = JSON.parse(element.config);
                let config2 = JSON.parse(config.config)
                vm.soilSensors[index].sensorId = config2.sensorId;
              });
              console.log(vm.soilSensors);
            }
          })
          .catch(function (error) {
            console.log("entra");
            console.log(error);
          });
      },
    async saveDavisSensor() {
        console.log("SELECTED ON DAVIS", this.selectedFarm);
        if (!this.selectedFarm) {
          this.notify("error", "Please select a farm/domain or station");
          return;
        }

        // if (!this.davisName) {
        //   this.notify("error", "There is no sensor name");
        //   return;
        // }
        if (!this.selectedDavisInstrument.station_name) {
          this.notify("error", "There is no station name");
          return;
        }

        // if (this.davisName.length > 50) {
        //   this.notify("error", "There is no sensor value");
        //   return;
        // }

        // if (!this.davisValue) {
        //   this.notify("error", "There is no sensor value/id");
        //   return;
        // }
        if (!this.selectedDavisInstrument.station_id) {
          this.notify("error", "There is no station id");
          return;
        }

        let stationId = this.selectedFarm.stationId;
        if (this.lastId) {
          stationId = this.lastId;
        }

        const newSensor = {
          name: this.selectedDavisInstrument.station_name,
          config: JSON.stringify({
            apiKey: this.apiKey,
            apiSecret: this.apiSecret,
            sensorId: this.selectedDavisInstrument.station_id,
          }),
          sensor_type: 1,
          stationId: stationId,
          farmId: this.itemData.id,
        };
        // const newSensor = {
        //   name: this.davisName,
        //   config: this.davisValue,
        //   sensor_type: 1,
        //   stationId: stationId,
        //   farmId: this.itemData.id,
        // };

        this.openLoading();

        try {
          await axios.post(`${API}/createDavisSensor`, newSensor);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error);
        } finally {
          this.seeSensors(this.selectedFarm);
          this.getFarms();
          this.closeLoading();
          this.soil = [];
          this.modalConnect = false;
        }
      },
      async saveDavisSensor2() {
        console.log("SELECTED ON DAVIS", this.selectedFarm);
        if (!this.selectedFarm) {
          this.notify("error", "Please select a farm/domain or station");
          return;
        }

        // if (!this.davisName) {
        //   this.notify("error", "There is no sensor name");
        //   return;
        // }
        if (!this.selectedDavisInstrument.station_name) {
          this.notify("error", "There is no station name");
          return;
        }

        // if (this.davisName.length > 50) {
        //   this.notify("error", "There is no sensor value");
        //   return;
        // }

        // if (!this.davisValue) {
        //   this.notify("error", "There is no sensor value/id");
        //   return;
        // }
        if (!this.selectedDavisInstrument.station_id) {
          this.notify("error", "There is no station id");
          return;
        }

        let stationId = this.selectedFarm.stationId;
        if (this.lastId) {
          stationId = this.lastId;
        }

        const newSensor = {
          name: this.selectedDavisInstrument.station_name,
          config: JSON.stringify({
            apiKey: this.apiKey,
            apiSecret: this.apiSecret,
            sensorId: this.selectedDavisInstrument.station_id,
          }),
          sensor_type: 1,
          stationId: stationId,
          farmId: this.itemData.id,
        };
        // const newSensor = {
        //   name: this.davisName,
        //   config: this.davisValue,
        //   sensor_type: 1,
        //   stationId: stationId,
        //   farmId: this.itemData.id,
        // };


        try {
          await axios.post(`${API}/createDavisSensor`, newSensor);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.notify("error", error);
        } finally {
          this.seeSensors(this.selectedFarm);
          this.getFarms();
          this.soil = [];
          this.modalConnect = false;
        }
      },
    soilClearSearch() {
      this.soil = [];
      this.modalConnect = false;
      setTimeout(() => {
        this.modalConnect = true;
      }, 500);
    },
    async soilVerifySerial() {
      var vm = this;
      this.soil.sensorData = [];
      this.soil.serialOk = false;

      if (!this.vEmpty(this.soil.serialNumber, "Soil Serial Number")) {
        return;
      }
      if (this.soil.serialNumber.length > 50) {
        this.notify("error", "Storage path is too large");
        return;
      }

      await axios
        .get(
          `https://quantumsync.org:3000/api/scouts/getScoutData/${this.soil.serialNumber}`
        )
        .then(function (data) {
          let response = data.data;
          if (response == "token_not_valid") {
            this.notify(
              "The serial number does not exist or has not yet been read by the API, wait a moment and check again"
            );
            return;
          } else {
            vm.soil.serialOk = true;
            vm.soil.sensorData = data.data;
            vm.soil.apiName = vm.soil.sensorData[0].name;
            vm.soil.apiValue = vm.soil.sensorData[0].serial_number;
            console.log(vm.soil.sensorData);
            vm.soil.serialNumber = null;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async testDavisConnection() {
        if (!this.vEmpty(this.apiKey, "api-key")) {
          return;
        }
        if (!this.vEmpty(this.apiSecret, "api-secret")) {
          return;
        }
        this.openLoading();

        try {
          axios
            .post(`${API}/testDavisConnection`, {
              api: this.apiKey,
              secret: this.apiSecret,
            })
            .then((response) => {
              console.log(response);
              this.closeLoading();
              if (response.data && parseInt(response.data.length) > 0) {
                this.showSaveDavis = true;
                this.davisInstruments = response.data;
                console.log(this.davisInstruments);
                this.notify("success", "Credentials successfully validated");
              } else {
                this.closeLoading();
                this.showSaveDavis = false;
                console.log(error);
                this.notify("error", "Wrong credentials");
              }
              //   console.log(response.data);
              //   console.log("Los tokens se han guardado en el archivo tokens.json");
              //   if (response.data == 'ok') {
              //     this.showSaveDavis = true;
              //     this.notify("success", 'User successfully validated');
              //   }
            })
            .catch((error) => {
              this.closeLoading();
              this.showSaveDavis = false;
              console.log(error);
              this.notify("error", "Wrong credentials");
            });
        } catch (error) {
          this.closeLoading();
          this.notify("error", "Wrong credentials");
          this.showSaveDavis = false;
        } finally {
          this.closeLoading();
        }
      },

    async getSensorType() {
      this.sensor_types = [];
      await axios
        .get(`${API}/sensor_type`)
        .then((response) => {
          this.sensor_types = response.data;
          console.log("sensor_types", this.sensor_types);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },

    connectSensor(item) {
        this.modalConnect = true;
        this.selectedFarm = [];
        this.selectedFarm = item;
        this.sensor_type = 1;
        console.log(item);
      },
    editUserModal(item) {
      this.modalEdit = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
      this.nameEdit = this.selectedFarm.name;
      this.storage_pathEdit = this.selectedFarm.storage_path;
    },
    deleteFarm() {
      axios
        .delete(`${API}/deleteFarm/${this.selectedFarm.id}`)
        .then((response) => {
          this.notify("success", "User deleted successfully");
        })
        .catch((error) => {
          console.error(error.response.data.error);
          this.notify("error", error.response.data.error);
        })
        .finally(() => {
          this.getFarms();
          this.getAllFarms();
          this.modalDelete = false;
        });
    },
    delFarmModal(item) {
      this.modalDelete = true;
      this.selectedFarm = [];
      this.selectedFarm = item;
    },
    async editFarm() {
      if (this.user) {
        if (!this.vEmpty(this.nameEdit, "Farm Name")) {
          return;
        }

        if (this.nameEdit.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_pathEdit, "Email")) {
          return;
        }

        if (this.storage_pathEdit.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          id: this.selectedFarm.id,
          name: this.nameEdit,
          storage_path: this.storage_pathEdit,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/editFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.nameEdit = null;
          this.storage_pathEdit = null;
          this.modalEdit = false;
          this.getFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async createStation() {
          if (!this.vEmpty(this.stationName, "Station Name")) {
            return;
          }

          if (this.stationName.length > 50) {
            this.notify("error", "Too many characters for a station name");
            return;
          }

          if (this.station_sensor === 0) {
            this.notify("error", "Please select a sensor");
            return;
          }

          let configJson;
          switch (this.station_sensor) {
            case 1:
              if (!this.selectedAgt) {
                if (!this.vEmpty(this.apiKey, "api-key")) {
                  return;
                }
                if (!this.vEmpty(this.apiSecret, "api-secret")) {
                  return;
                }
                configJson = {
                  apiKey: this.apiKey,
                  apiSecret: this.apiSecret,
                };
              } else {
                configJson = {
                  apiKey: null,
                  apiSecret: null,
                };
              }
              break;
            case 2:
              config = "test";
              break;
            case 3:
              if (!this.selectedAgt) {
                if (!this.vEmpty(this.apiKey, "Soil Socuts Username")) {
                  return;
                }
                if (!this.vEmpty(this.apiSecret, "Soil Socuts Password")) {
                  return;
                }
                configJson = {
                  username: this.apiKey,
                  password: this.apiSecret,
                };
              } else {
                configJson = {
                  username: null,
                  password: null,
                };
              }
              break;
            case 4:
              config = "test";
              break;

            default:
              break;
          }

          const newStation = {
            name: this.stationName,
            farmId: this.stationFarmId,
            sensor: this.station_sensor,
            selectedAgt: this.selectedAgt,
            config: JSON.stringify(configJson),
          };

          this.openLoading();

          let lastInsertId = null; // Inicializa la variable para almacenar el último ID insertado

          try {
            const response = await axios.post(`${API}/createStation`, newStation);
            lastInsertId = response.data.lastId;
            await this.updateUserInfo(this.user.parent_uid);
          } catch (error) {
            this.closeLoading();
            this.notify("error", error.response.data.error);
          } finally {
            this.stationName = null;
            console.log("ULTIMO ID", lastInsertId);
            this.getFarms();
            setTimeout(() => {
              this.stationFarmId = lastInsertId;
              this.selectedFarm.stationId = lastInsertId;
              this.lastId = lastInsertId;
              console.log("Asignacion", this.lastId);
              this.soilConfig = false;
              this.saveDavisSensor2();
              this.closeLoading();
            }, 2000);
          }

      },
    async createFarm() {
      if (this.user) {
        if (!this.vEmpty(this.name, "Farm Name")) {
          return;
        }

        if (this.name.length > 50) {
          this.notify("error", "Too many characters for a farm name");
          return;
        }

        if (!this.vEmpty(this.storage_path, "Storage Path")) {
          return;
        }

        if (this.storage_path.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }

        const newFarm = {
          user_id: this.user.id,
          name: this.name,
          storage_path: this.storage_path,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/createFarm`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.notify("error", error.response.data.error);
        } finally {
          this.name = null;
          this.storage_path = null;
          this.notify("success", "Successfully created domain");
          this.getFarms();
          this.getAllFarms();
          this.closeLoading();
        }
      } else {
        this.notify("error", "There is no user loggued in.");
        return;
      }
    },
    async getFarms() {
      this.farms = [];
      await axios
      .get(`${API}/farms-single/${this.userId}`)
      .then((response) => {
        console.log("GET ALL FARMS", response);
          this.farms = response.data;
        })
        .catch((error) => {
          console.error("********Error getting all farms", error);
        });
    },
    async getAllFarms() {
      this.allFarms = [];
      await axios
        .get(`${API}/allFarms/${this.userId}`)
        .then((response) => {
          this.allFarms = response.data;
          console.log("ALL FARMS");
          console.log(this.allFarms);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>
